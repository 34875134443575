.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: rgba(233, 232, 222, 0.8); 

  
  

  .logo {
    img {
      height: 70px;
    }
  }

  .navbar {
    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      margin: 0;
      padding: 0;

      li {
        a {
          text-decoration: none;
          font-size: 18px;
          color: #333;
          padding: 10px;
          border-radius: 5px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }

          &.contactBtn {
            border: 1px solid #333;
            padding: 10px 20px;
            border-radius: 25px;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }


  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 20px;

    .logo img {
      height: 50px; 
    }

    .navbar ul {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
    }

    .navbar ul li a {
      font-size: 16px; 
    }
  }

  @media (max-width: 480px) {
    padding: 2rem;

    .navbar ul li a {
      font-size: 14px;
      padding: 6px;
    }
  }
}
