.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:rgba(233, 232, 222, 0.8);
  height: fit-content;
 

  .imageContainer {
    flex: 1;
    position: relative; // Adiciona position relative para controlar o posicionamento do pseudo-elemento

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      
    }

    // Pseudo-elemento para a película escura
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background-color: rgba(0, 0, 0, 0.3); 
     
      z-index: 1;
    }

    // Isso garante que qualquer conteúdo dentro do container, como a imagem, fique abaixo da película
    img {
      position: relative;
      z-index: 0; // Coloca a imagem abaixo da película
    }
  }

  .infoContainer {
    flex: 1;
    padding: 3rem;

    h1 {
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }

    .contactList {
      list-style: none;
      padding: 0;
      margin-top: 2rem;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        color: #333;

        svg {
          margin-right: 10px;
          color: #555;
          font-size: 1.5rem;
        }
      }
    }

    .noOffice {
      margin-top: 20px;
      font-style: italic;
      color: #666;
    }
  }
}

@media (max-width: 768px) {
  .contactContainer {
    flex-direction: column;

    .imageContainer {
      margin-bottom: 20px;
    }

    .infoContainer {
      text-align: center;

      .contactList li {
        justify-content: center;
      }
    }
  }
}
