.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 10rem;

    p{
        text-align: justify;
        line-height: 1.5;
        margin: 0;
    }

  @media (max-width: 768px) {
        padding:  1rem 2rem ;
        flex-direction: column;
        height: auto;
  
        
    }
} 
 
