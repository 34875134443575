.galleryContainer {
    padding: 20px;
    text-align: center;
  }
  
  .slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .photoTrack {
    display: flex;
    overflow: hidden;
  }
  
  .photoWrapper {
    position: relative;
    min-width: 300px;
    margin: 0 1rem;
  }
  
  .photo {
    width:300px;
    height:200px;
    display: block;
    transition: transform 0.3s;
    object-fit: cover; 
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7); 
    color: white; 
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out; 
    font-size: 0.9rem;
    border-radius: 10px;
  }
  .photoWrapper:hover .description {
    opacity: 1; 
  }
  
  .arrow {
    background: none;
    border: none;
    font-size: 2rem;
    color: #333;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s;
  
    &:hover {
      transform: scale(1.1);
    }
  }

  h2{
  
    font-weight: 400;
    color: #333;
    margin: 0%;
  }
  
  @media (max-width: 768px) {

 
    .photoWrapper {
        min-width: 50%; 
      }
    
      .arrow {
        font-size: 1.5rem;
      }
  }
  
  @media (max-width: 480px) {

    .photoWrapper {
        min-width: 100%;
      }
    
      .arrow {
        font-size: 1.2rem;
      }
  }
  