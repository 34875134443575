.differentSection {
    padding: 40px 10%;
    background-color: #E9E8DE; // Cor de fundo opcional
    text-align: center;
  

  
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
  
      @media (max-width: 768px) {
        grid-template-columns: 1fr; 
      }
    }
  
    .topic {
      background-color: #fff; 
      border-radius: 16px; 
      padding: 20px;
      display: flex;
      align-items: center; 
      gap: 20px;
      .icon {
        font-size: 2rem; 
       
        color: #424F5C; 
      }
  
      .textContainer {
        text-align: start; 
  
        h3 {
          margin: 0 0 8px; 
          color: #333;
        }
  
        p {
          margin: 0; 
          color: #666; 
        }
      }
    }
  }
  