.servicesContainer {
    padding: 20px;
    text-align: center;
  
    h2 {
      
      font-weight: 500;
      margin: 0%;
      color: #333;
    }
  }
  
  .servicesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Duas colunas
    gap: 20px;
    max-width: 800px; /* Define um limite de largura para centralizar */
    margin: 0 auto; /* Centraliza a lista na página */
  }
  
  .serviceItem {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left; /* Alinha o texto à esquerda */
    
    p {
      
      color: #333;
      margin: 0;
      text-transform: uppercase;
    }
  }
  
  .icon {
    width: 40px;  
    height: auto;
  }
  
  a.serviceItem {
    text-decoration: none; 
    color: inherit; 
  }
  

  
 
    
  
  
  // Responsividade
  @media (max-width: 768px) {
    .servicesList {
      grid-template-columns: 1fr; // Uma coluna em telas menores
      justify-content: center; /* Centraliza a lista */
    }
  
    .serviceItem {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .icon {
      font-size: 1.5rem;
    }
  
    .button {
      padding: 8px 16px;
    }
  }
  