.sessaoImagemTexto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #424F5C;
    height: 400px;
  
    .imagemContainer {
      width: 50%;
      height: 100%; /* A imagem acompanha o tamanho da div */
      
      img {
        width: 100%;
        height: 100%; /* A imagem ocupa o tamanho total da div */
        object-fit: cover; /* A imagem é redimensionada sem distorção */
      }
    }
  
    .textoContainer {
      width: 50%;
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      gap:2rem;
  
      h2 {
        font-weight: lighter;
        color: #ffffff;
        line-height: 1.4;
        margin-bottom: 0.8rem; /* Reduz a margem entre o título e o texto */
      }
  
      p {
        color: #ffffff;
        margin: 0; /* Reduz a margem entre os parágrafos */
      }
  
      button {
        margin-top: 2.5rem; /* Empurra o botão para baixo, respeitando o conteúdo */
        align-self: flex-start; /* Alinha o botão à esquerda */
      }
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;

      h2, p{
        padding:  0 20px ;
        text-align: start;

      }
  
      .imagemContainer,
      .textoContainer {
        width: 100%;
      }
  
      .textoContainer {
        padding: 20px 0;
        text-align: center;
  
        button {
          align-self: center; /* Centraliza o botão no mobile */
        }
      }
    }
  }
  