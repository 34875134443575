.divider {
    width: 180px; /* Largura da linha */
      /* Altura da linha */
    margin: 20px auto; /* Centraliza a linha e adiciona espaçamento */
  }
  
  .light {
    background-color: #ffffff; /* Cor branca */
  }
  
  .dark {
    background-color: #333; /* Cor escura */
  }
  