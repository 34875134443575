.servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 40px; 
    padding: 20px;
    align-items: center;
    cursor: context-menu;
    .serviceItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #ccc;
  
      .serviceImage {
        flex: 1 1 50%; 
        display: flex;
        justify-content: center; 
        img {
          width: 80%;
          
          height: 440px;
          object-fit: cover;
          border-radius: 8px;
        }
      }
  
      .serviceText {
        flex: 1 1 50%; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px; 
        text-align: left;
  
        h2 {
          margin: 10px 0;
          color: #333;
          
          text-transform: uppercase;
         
        }
  
        p {
          
          color: #666;
        }
      }
    }
  
    
    .reversed {
      flex-direction: row-reverse;
    }
  
    @media (max-width: 768px) {
      .serviceItem {
        flex-direction: column;
        text-align: center;
  
        .serviceImage,
        .serviceText {
          max-width: 100%;
          padding: 0;
          
        }

        .serviceText{
          text-align: center;
          margin-top: 10px;
        }
      }
  
      .reversed {
        flex-direction: column;
      }
    }
  }
  