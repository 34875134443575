.footer {
  background-color: #E9E8DE;
  color: #333;
  padding: 20px 10%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  cursor: context-menu;
  @media (max-width: 768px) {
    padding: 20px 5%;
  }

  .footerContainer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      width: 100%;
      justify-items: center; 

      @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr); 
      }

      @media (max-width: 768px) {
        grid-template-columns: 1fr; 
      }

      .icon {
        font-size: 1.2rem; // Tamanho maior para os ícones
        margin-right: 8px; // Espaçamento entre o ícone e o texto
      }

      .servicesContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Duas colunas */
        gap: 20px; /* Espaçamento entre as duas listas */
    
        ul {
          list-style: none;
          padding: 0;
    
          li {
            margin-bottom: 8px;
    
            a {
              color: #333;
              text-decoration: none;
              transition: color 0.3s;
              cursor: pointer;
    
              &:hover {
                color: #424F5C;
              }
            }
          }
        }
      }
    
      @media (max-width: 768px) {
        .servicesContainer {
          grid-template-columns: 1fr; /* Em telas menores, uma coluna */
        }
      }
  }
  .location {
    display: flex;
    align-items: center;
    gap: 10px;
    
  }
  
  .footerColumn {
    display: flex;
    flex-direction: column;
    align-items: start;   
    text-align: start;

    h3 {
      
      margin-bottom: 10px;
      color: #333;
    }

    p {
     
      margin: 0;
      
    }

    ul {
      list-style: none; 
      padding: 0;

      li {
        margin-bottom: 8px;

        a {
          color: #333;
          text-decoration: none; 
          transition: color 0.3s;
          cursor: pointer;
          

          &:hover {
            color: #424F5C; 
          }
        }
      }
    }
  }

  .logo {
    width: 60%;  
    height: auto;
    margin:   auto;
    padding: 20px 0;
  
    @media (max-width: 1200px) {
      width: 30%; 
    }
  }
}
